<template>
  <div class="ContentList" :class="classes">
    <h3 class="ContentList-title" v-html="title" v-if="title"></h3>

    <dl class="ContentList-list">
      <div
        class="ContentList-listItem"
        v-for="(item, index) in list"
        :key="index"
      >
        <dt class="ContentList-key" v-html="item.key"></dt>

        <dd class="ContentList-value" v-html="item.value"></dd>
      </div>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'ContentList',

  props: {
    list: { type: Array, default: () => [] },
    address: { type: Boolean, default: false },
    title: { type: String, default: undefined }
  },

  computed: {
    classes() {
      return {
        [`${this.$options.name}--address`]: this.address
      }
    }
  }
}
</script>

<style lang="scss">
.ContentList {
  margin-top: 1em;
  margin-bottom: 1em;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.ContentList-title {
  margin-bottom: 1em;
}

.ContentList-listItem {
  display: flex;
}

.ContentList-key {
  width: 60px;
  font-feature-settings: 'ss01';
  flex: none;

  .ContentList--address & {
    width: 35px;
    font-feature-settings: 'ss03';
  }
}
</style>
