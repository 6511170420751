exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".GalleryItem{height:100%}.GalleryItem.v-enter-active,.GalleryItem.v-leave-active{-webkit-transition:opacity .25s linear;transition:opacity .25s linear}.GalleryItem.v-enter,.GalleryItem.v-leave-to{opacity:0}.GalleryItem.v-leave,.GalleryItem.v-enter-to{opacity:1}.GalleryItem-image{display:block;position:absolute}@media(max-width: 700px){.GalleryItem-image{width:100%;max-height:calc(var(--AppHeight) - var(--padding) - var(--padding));-o-object-fit:contain;object-fit:contain;-o-object-position:top left;object-position:top left}}", ""]);

// Exports
exports.locals = {
	"color-grey-100": "#fafafa",
	"color-grey-200": "#e6e6e6",
	"color-grey-300": "#dcdcdc",
	"color-grey-400": "#d2d2d2",
	"color-grey-500": "#c8c8c8",
	"color-grey-900": "#282828"
};