import clamp from 'lodash/clamp'
import forEach from 'lodash/forEach'

const map = function(value, low1, high1, low2, high2) {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1)
}

export const state = () => ({
  points: {}
})

export const mutations = {
  setPoint(state, { id, point }) {
    state.points[id] = point
  },

  removePoint(state, id) {
    if (state.points[id]) delete state.points[id]
  }
}

export const getters = {
  getDistanceToClosestPointFor: state => ({ x, y }) => {
    let smallestDistance = undefined

    forEach(state.points, point => {
      let distance

      distance = Math.hypot(x - point.x, y - point.y)
      if (point.mode === 'horizontal') distance = Math.abs(x - point.x)
      if (point.mode === 'vertical') distance = Math.abs(y - point.y)

      if (smallestDistance === undefined) smallestDistance = distance
      if (distance < smallestDistance) smallestDistance = distance
    })

    let distanceFactor = map(smallestDistance, 0, 200, 0, 1)
    distanceFactor = clamp(distanceFactor, 0, 1)

    return distanceFactor
  }
}

export const actions = {}
