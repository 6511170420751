exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DefaultImage{background-color:#e6e6e6;position:relative;width:100%;height:100%;opacity:1}.DefaultImage .lazyloading,.DefaultImage .lazyload{opacity:0}.DefaultImage .lazyloaded{opacity:1;-webkit-animation-duration:.25s;animation-duration:.25s;-webkit-animation-name:fadeIn;animation-name:fadeIn;-webkit-animation-fill-mode:both;animation-fill-mode:both}.DefaultImage--noBackground{background-color:transparent}@-webkit-keyframes fadeIn{0%{opacity:0}100%{opacity:1}}@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}.DefaultImage.is-withAspectRatio::before{content:\"\";display:block;width:100%;padding-top:100%}.DefaultImage.is-withAspectRatio.is-1to1::before{padding-top:100%}.DefaultImage.is-withAspectRatio.is-4to3::before{padding-top:75%}.DefaultImage.is-withAspectRatio img{position:absolute;top:0;left:0;width:100%;height:100%}", ""]);

// Exports
exports.locals = {
	"color-grey-100": "#fafafa",
	"color-grey-200": "#e6e6e6",
	"color-grey-300": "#dcdcdc",
	"color-grey-400": "#d2d2d2",
	"color-grey-500": "#c8c8c8",
	"color-grey-900": "#282828"
};