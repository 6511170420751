<template>
  <div class="ProjectOverview">
    <ProjectOverviewItem
      v-for="project in projects"
      :key="project.id"
      :image="project.cover"
      :title="project.title"
      :url="project.url"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProjectOverviewItem from '@/components/ProjectOverviewItem.vue'
import { TweenLite } from 'gsap'

if (process.client) {
  require('gsap/ScrollToPlugin')
}

export default {
  name: 'ProjectOverview',
  components: { ProjectOverviewItem },

  computed: {
    ...mapState('data', ['projects', 'scrollPositionForHome']),
    ...mapState(['scrollPositionForHome'])
  },

  async mounted() {
    if (this.scrollPositionForHome) {
      // wait because of Safari
      await new Promise(resolve => setTimeout(resolve, 0))

      TweenLite.set(document.scrollingElement, {
        scrollTo: this.scrollPositionForHome
      })
    }
  }
}
</script>

<style lang="scss">
.ProjectOverview {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;

  @include media('<=750px') {
    grid-template-columns: 1fr 1fr;
  }

  @include media('<=550px') {
    grid-template-columns: 1fr;
  }

  @include media('>1200px') {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include media('>1600px') {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>
