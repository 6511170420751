<template>
  <div class="Gallery" :class="classes.root">
    <transition>
      <div
        class="Gallery-title"
        :class="classes.title"
        v-html="title"
        ref="title"
        v-show="titleVisible && isMounted"
        data-flip-key="title"
      ></div>
    </transition>

    <template>
      <!-- Because of a strange bug I have to do this v-if v-else-->
      <transition v-if="isMobile">
        <div
          class="Gallery-description"
          v-show="descriptionVisible"
          v-if="description"
          v-html="description"
          data-flip-key="description"
          ref="description"
        ></div>
      </transition>

      <div
        v-else
        class="Gallery-description"
        v-show="description && descriptionVisible"
        v-html="description"
        data-flip-key="description"
        ref="description"
      ></div>
    </template>

    <div class="Gallery-images">
      <transition mode="out-in">
        <GalleryItem
          :image="currentImage"
          :key="currentImage.name"
          ref="image"
          :descriptionVisible="descriptionVisible"
        />
      </transition>
    </div>

    <div class="Gallery-buttons">
      <button
        class="Gallery-button"
        @click="goToPrevious"
        @mouseenter="$eventHub.$emit('Cursor.previous')"
        @mouseleave="$eventHub.$emit('Cursor.default')"
      ></button>

      <button
        class="Gallery-button"
        @click="goToNext"
        @mouseenter="$eventHub.$emit('Cursor.next')"
        @mouseleave="$eventHub.$emit('Cursor.default')"
      ></button>
    </div>

    <GalleryControl
      class="Gallery-control"
      :count="totalImages"
      :hasDescription="hasDescription"
      :currentIndex="currentIndex + 1"
      :descriptionVisible="descriptionVisible"
      @goToIndex="goToIndex"
      @toggleDescription="toggleDescription"
      v-show="galleryControlVisible"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import GalleryItem from '@/components/GalleryItem.vue'
import GalleryControl from '@/components/GalleryControl.vue'
import layout from '@/mixins/layout'
import Flipping from 'flipping'
import { TweenLite } from 'gsap'

if (process.client) {
  require('gsap/ScrollToPlugin')
}

export default {
  name: 'Gallery',
  components: { GalleryItem, GalleryControl },
  mixins: [layout],

  data() {
    return {
      descriptionVisible: false,
      isMobile: undefined,
      isMounted: false,
      titleIsLeft: false,
      dataIndex: 1
    }
  },

  props: {
    title: { type: String, default: undefined },
    images: { type: Array, default: () => [] },
    description: { type: String, default: undefined }
  },

  computed: {
    ...mapState(['infoVisible', 'galleryControlVisible']),

    currentIndex() {
      // if (!this.$route.query.index) return 0
      // return parseInt(this.$route.query.index - 1)

      return this.dataIndex - 1
    },

    totalImages() {
      return this.images.length
    },

    hasDescription() {
      if (this.description.length > 0) return true
      return false
    },

    currentImage() {
      return this.images[this.currentIndex]
    },

    titleVisible() {
      if (!this.isMobile) return true

      return this.descriptionVisible
    },

    classes() {
      return {
        root: {
          'is-infoVisible': this.infoVisible,
          'is-descriptionVisible': this.descriptionVisible
        },
        title: {
          'is-left': this.titleIsLeft
        }
      }
    }
  },

  watch: {
    infoVisible() {
      if (this.infoVisible) this.makeTypoInvisible()
      else this.makeTypoVisible()
    }
  },

  mounted() {
    this.isMounted = true
  },

  methods: {
    layout() {
      this.isMobile = window.matchMedia('(max-width: 700px)').matches
    },

    goToPrevious() {
      let newIndex

      if (this.currentIndex === 0) newIndex = this.totalImages - 1
      else newIndex = this.currentIndex - 1

      this.goToIndex(newIndex)
    },

    goToNext() {
      let newIndex

      if (this.currentIndex === this.totalImages - 1) newIndex = 0
      else newIndex = this.currentIndex + 1

      this.goToIndex(newIndex)
    },

    goToIndex(index) {
      // this.$router.replace({ params: { currentIndex: index + 1 } })
      this.dataIndex = index + 1
    },

    toggleDescription() {
      // this.descriptionVisible = !this.descriptionVisible
      if (!this.descriptionVisible) this.transitionDescriptionIn()
      else this.transitionDescriptionOut()
    },

    transitionDescriptionIn() {
      if (this.isMobile) this.transitionDescriptionInMobile()
      else this.transitionDescriptionInFull()
    },

    async transitionDescriptionInMobile() {
      const duration = 0.5

      const flipping = new Flipping({
        parent: this.$el,
        duration: 10000
      })
      flipping.read()

      this.descriptionVisible = true
      await this.$nextTick()
      await this.$nextTick()
      flipping.flip()

      TweenLite.set(this.$refs.image.$refs.image, {
        y: flipping.states.image.delta.top
      })

      TweenLite.to(this.$refs.image.$refs.image, duration, {
        y: 0,
        clearProps: 'all'
      })
    },

    async transitionDescriptionInFull() {
      const duration = 0.5

      const flipping = new Flipping({
        parent: this.$el
      })
      flipping.read()

      this.descriptionVisible = true

      // wait for two ticks because of image layout:
      await this.$nextTick()
      await this.$nextTick()

      flipping.flip()

      // Image
      TweenLite.set(this.$refs.image.$refs.image, {
        position: 'absolute',
        scaleX: flipping.states.image.delta.width,
        scaleY: flipping.states.image.delta.height,
        transformOrigin: 'top left'
      })

      TweenLite.to(this.$refs.image.$refs.image, duration, {
        scale: 1,
        clearProps: 'position, transform, transform-origin'
      })

      TweenLite.to(this.$refs.title, duration, {
        opacity: 0,
        onComplete: () => {
          this.titleIsLeft = true
        }
      })

      // let delay = 0
      // if (
      //   flipping.states.image.delta.width > 1 ||
      //   flipping.states.image.delta.height > 1
      // ) {
      //   delay = duration
      // }

      let delay = duration

      // Description
      TweenLite.set(this.$refs.description, {
        // x: '100%'
        opacity: 0
      })

      TweenLite.to(this.$refs.description, duration, {
        // x: '0%',
        opacity: 1,
        clearProps: 'all',
        delay: delay
      })

      TweenLite.to(this.$refs.title, duration, {
        opacity: 1,
        delay: delay
      })
    },

    transitionDescriptionOut() {
      if (this.isMobile) this.transitionDescriptionOutMobile()
      else this.transitionDescriptionOutFull()
    },

    async transitionDescriptionOutMobile() {
      const duration = 0.5

      const flipping = new Flipping({
        parent: this.$el,
        duration: 10000
      })
      flipping.read()

      const rect = this.$refs.image.$refs.image.getBoundingClientRect()

      this.descriptionVisible = false
      await this.$nextTick()
      await this.$nextTick()
      flipping.flip()

      TweenLite.set(this.$refs.image.$refs.image, {
        position: 'fixed',
        y: rect.top,
        width: rect.width,
        top: 0
      })

      TweenLite.to(this.$refs.image.$refs.image, duration, {
        y: 10,
        delay: 0.25,
        clearProps: 'all'
      })
    },

    async transitionDescriptionOutFull() {
      const duration = 0.5

      const flipping = new Flipping({
        parent: this.$el
      })
      flipping.read()

      const scrollTop = document.scrollingElement.scrollTop

      this.descriptionVisible = false
      // wait for two ticks because of image layout:
      await this.$nextTick()
      await this.$nextTick()

      flipping.flip()

      TweenLite.fromTo(
        window,
        duration,
        {
          scrollTo: scrollTop
        },
        { scrollTo: 0 }
      )

      TweenLite.set(this.$refs.title, {
        left: flipping.states.title.previous.bounds.left,
        top: flipping.states.title.previous.bounds.top,
        height: flipping.states.title.previous.bounds.height,
        width: flipping.states.title.previous.bounds.width,
        display: 'block',
        position: 'absolute',
        gridArea: 'none'
      })

      TweenLite.to(this.$refs.title, duration / 2, {
        opacity: 0,
        clearProps: 'left, top, height, width, display, position, gridArea',
        onComplete: () => {
          this.titleIsLeft = false
        }
      })

      // let delay = 0
      // if (
      //   flipping.states.image.delta.width < 1 ||
      //   flipping.states.image.delta.height < 1
      // ) {
      //   delay = duration / 2
      // }

      let delay = duration / 2

      // Image
      TweenLite.set(this.$refs.image.$refs.image, {
        position: 'absolute',
        scaleX: flipping.states.image.delta.width,
        scaleY: flipping.states.image.delta.height,
        transformOrigin: 'top left'
      })

      TweenLite.to(this.$refs.image.$refs.image, duration, {
        scale: 1,
        clearProps: 'position, transform, transform-origin',
        delay: delay
      })

      // Description
      TweenLite.set(this.$refs.description, {
        // x: '0%',
        left: flipping.states.description.previous.bounds.left,
        top: flipping.states.description.previous.bounds.top,
        height: flipping.states.description.previous.bounds.height,
        width: flipping.states.description.previous.bounds.width,
        display: 'block',
        position: 'absolute',
        gridArea: 'none'
      })

      // TweenLite.to(this.$refs.description, duration, {
      //   x: '100%',
      //   clearProps: 'all',
      //   onComplete: () => {
      //     TweenLite.set(this.$refs.description, {
      //       display: 'none'
      //     })
      //   }
      // })

      TweenLite.to(this.$refs.description, duration / 2, {
        opacity: 0,
        clearProps: 'all',
        onComplete: () => {
          TweenLite.set(this.$refs.description, {
            display: 'none'
          })
        }
      })

      TweenLite.to(this.$refs.title, duration, {
        opacity: 1,
        delay: delay
      })
    },

    makeTypoInvisible() {
      if (this.isMobile) return

      const duration = 0.4

      TweenLite.to(this.$refs.description, duration, {
        opacity: 0
      })

      TweenLite.to(this.$refs.title, duration, {
        opacity: 0
      })
    },

    makeTypoVisible() {
      const duration = 0.4

      TweenLite.to(this.$refs.description, duration, {
        opacity: 1
      })

      TweenLite.to(this.$refs.title, duration, {
        opacity: 1
      })
    }
  }
}
</script>

<style lang="scss">
.Gallery {
  min-height: calc(var(--AppHeight) - var(--padding) - var(--padding));
  position: relative;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
  align-items: start;
  grid-template-areas:
    'images title'
    'images description';

  &.is-descriptionVisible {
    // height: auto;
    grid-template-columns: 1fr 1fr;
  }

  &.is-infoVisible {
    height: calc(var(--AppHeight) - var(--padding) - var(--padding));
  }

  @include media('<=mobile') {
    height: calc(var(--AppHeight) - var(--padding) - var(--padding));
    grid-template-columns: 1fr !important;
    grid-template-rows: auto auto 1fr;
    grid-gap: 0;
    grid-template-areas: 'title' 'description' 'images';
  }
}

.Gallery-control {
  position: fixed;
  bottom: calc(20px + var(--padding));
  left: var(--headerWidth);
  width: calc(100vw - var(--headerWidth) - var(--padding));

  .Gallery.is-infoVisible & {
    @include media('<=mobile') {
      display: none;
    }
  }
}

.Gallery-images {
  grid-area: images;
  width: 80%;
  height: 100%;
  max-height: calc(var(--AppHeight) - var(--padding) - var(--padding));
  position: relative;

  .Gallery.is-descriptionVisible & {
    width: 100%;
  }

  .Gallery.is-infoVisible & {
    top: 0;
  }

  @include media('<=mobile') {
    width: 100%;
  }
}

.Gallery-buttons {
  // outline: 1px solid red;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;

  @include media('>mobile') {
    .Gallery.is-descriptionVisible & {
      grid-area: images;
    }
  }

  @include media('<=mobile') {
    grid-area: images;
  }
}

.Gallery-button {
  // background-color: rgba(blue, 0.1);
  // border: 1px solid rgba(blue, 0.5);
  width: 50%;
}

.Gallery-title {
  grid-area: title;
  text-align: right;

  &.is-left {
    text-align: left;
  }

  @include media('<=mobile') {
    margin-bottom: 10px;
    text-align: left;

    &.v-enter-active,
    &.v-leave-active {
      transition: opacity 0.4s linear;
    }

    &.v-enter-active {
      transition-delay: 0.25s;
    }

    &.v-enter,
    &.v-leave-to {
      opacity: 0;
    }

    &.v-leave,
    &.v-enter-to {
      opacity: 1;
    }
  }
}

.Gallery-description {
  grid-area: description;
  font-weight: normal;
  // position: sticky;
  position: relative;
  // top: 0;
  transition: none;
  max-width: 25em;

  & p {
    margin-bottom: 1em;
  }

  .Gallery.is-descriptionVisible & {
    position: relative;
  }

  @include media('<=mobile') {
    position: relative;
    margin-bottom: 10px;

    &.v-enter-active,
    &.v-leave-active {
      transition: opacity 0.4s linear;
    }

    &.v-enter-active {
      transition-delay: 0.25s;
    }

    &.v-enter,
    &.v-leave-to {
      opacity: 0;
    }

    &.v-leave,
    &.v-enter-to {
      opacity: 1;
    }
  }
}
</style>
