import 'lazysizes'
import 'lazysizes/plugins/bgset/ls.bgset.js'
import 'lazysizes/plugins/parent-fit/ls.parent-fit.js'
import 'lazysizes/plugins/respimg/ls.respimg.js'
import Vue from 'vue'

// IntersectionObserver.prototype.THROTTLE_TIMEOUT = 16

import ReactiveWindow from '@/plugins/ReactiveWindow'
Vue.prototype.$window = ReactiveWindow
