<template>
  <div class="Project">
    <Gallery
      v-if="project"
      :images="project.images"
      :description="project.body"
      :title="project.title"
    />
  </div>
</template>

<script>
import axios from 'axios'
import Gallery from '@/components/Gallery.vue'

export default {
  name: 'Project',
  layout: process.env.VUE_APP_DEFAULT_LAYOUT,
  components: { Gallery },

  data() {
    return {
      project: undefined,
      asyncDataRanOnClient: false
    }
  },

  props: {
    id: { type: Number, required: true }
  },

  head() {
    return {
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.project.metaDescription
        }
      ]
    }
  },

  async asyncData(context) {
    const id = context.route.matched[0].meta.id
    const { data } = await axios.get(`project/${id}`)

    return {
      project: data,
      asyncDataRanOnClient: process.client
    }
  },

  async created() {
    if (!process.client) return
    if (this.asyncDataRanOnClient) return

    this.$store.commit('loadingStart')
    const { data } = await axios.get(`project/${this.id}`)

    // only overwrite if something changed since last prerendering:
    if (JSON.stringify(this.project) !== JSON.stringify(data)) {
      this.project = data
    }

    this.$store.commit('loadingEnd')
  }
}
</script>

<style lang="scss">
.Project {
  //
}
</style>
