<template>
  <div class="DefaultLayout" :class="classes.root">
    <div
      class="DefaultLayout-info"
      v-show="infoVisible"
      ref="info"
      data-flip-key="info"
    >
      <Info />
    </div>

    <header class="DefaultLayout-header" ref="header" data-flip-key="header">
      <router-link to="/" class="DefaultLayout-logo">
        <SvgLogo />
      </router-link>
    </header>

    <button
      class="DefaultLayout-toggleInfo"
      @click="toggleInfo"
      ref="toggleInfo"
    >
      <Icon
        class="DefaultLayout-toggleInfoIcon"
        name="ArrowLeft"
        v-if="infoVisible"
      />
      <span
        class="DefaultLayout-toggleInfoText"
        :class="classes.toggleInfoText"
      >
        <SvgInfo />
      </span>
    </button>

    <button
      class="DefaultLayout-hideInfo"
      @click="hideInfo"
      @mouseover="infoVisible ? $eventHub.$emit('Cursor.previous') : null"
      @mouseleave="infoVisible ? $eventHub.$emit('Cursor.default') : null"
      v-if="infoVisible"
    ></button>

    <div
      class="DefaultLayout-content"
      :class="classes.content"
      ref="content"
      data-flip-key="content"
    >
      <div class="DefaultLayout-contentSlot">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Info from '@/components/Info.vue'
import layout from '@/mixins/layout'
import Flipping from 'flipping'
import { TweenLite } from 'gsap'
import SvgLogo from '@/components/SvgLogo.vue'
import SvgInfo from '@/components/SvgInfo.vue'

if (process.client) {
  require('gsap/ScrollToPlugin')
}

export default {
  name: 'DefaultLayout',
  components: { Info, SvgLogo, SvgInfo },
  mixins: [layout],

  data() {
    return {
      contentTargetSize: false,
      isMobile: false
    }
  },

  computed: {
    ...mapState(['infoVisible']),
    ...mapState('data', ['pageTitle', 'pageAltTitle']),

    classes() {
      return {
        root: {
          'is-infoVisible': this.infoVisible
        },
        content: {
          'is-targetSize': this.contentTargetSize
        },
        toggleInfoText: {
          'is-hidden': this.infoVisible
        }
      }
    }
  },

  methods: {
    layout() {
      this.isMobile = window.matchMedia('(max-width: 700px)').matches

      // padding bottom for logo:
      this.$el.style.setProperty(
        '--logoPaddingBottom',
        `${this.$refs.toggleInfo.getBoundingClientRect().height}px`
      )

      const rectHeader = this.$refs.header.getBoundingClientRect()
      this.$store.commit('distance/setPoint', {
        id: 'header',
        point: {
          x: rectHeader.x + rectHeader.width / 2,
          y: rectHeader.y,
          mode: 'horizontal'
        }
      })
    },

    hideInfo() {
      if (!this.infoVisible) return
      this.transitionInfoOut()
    },

    toggleInfo() {
      if (!this.infoVisible) this.transitionInfoIn()
      else this.transitionInfoOut()
    },

    transitionInfoIn() {
      if (this.isMobile) this.transitionInfoInMobile()
      else this.transitionInfoInFull()
    },

    async transitionInfoInMobile() {
      this.$store.commit('hideGalleryControl')

      const duration = 0.5
      const easing = Power1.easeInOut

      TweenLite.to(window, duration, {
        scrollTo: 0
      })

      const flipping = new Flipping()
      flipping.read()

      this.$store.commit('showInfo')
      await this.$nextTick()

      flipping.flip()

      // Content
      TweenLite.set(this.$refs.content, {
        position: 'absolute',
        left: flipping.states.content.bounds.left,
        x: flipping.states.content.delta.left
      })

      TweenLite.to(this.$refs.content, duration, {
        x: 0,
        clearProps: 'all',
        ease: easing,
        onComplete: () => {
          this.$refs.content.style.overflow = 'hidden'
          this.$refs.content.style.position = 'fixed'
          this.$refs.content.style.left = '100%'
        }
      })

      // Header
      TweenLite.set(this.$refs.header, {
        position: 'fixed',
        left: flipping.states.header.bounds.left,
        height: flipping.states.header.bounds.height,
        x: flipping.states.header.delta.left
      })

      TweenLite.to(this.$refs.header, duration, {
        x: 0,
        ease: easing,
        clearProps: 'all'
      })

      // ToggleInfo
      TweenLite.set(this.$refs.toggleInfo, {
        x: flipping.states.header.delta.left
      })

      TweenLite.to(this.$refs.toggleInfo, duration, {
        x: 0,
        ease: easing,
        clearProps: 'all'
      })

      // Info
      TweenLite.set(this.$refs.info, {
        position: 'absolute',
        left: flipping.states.info.bounds.left,
        x: '-100%'
      })

      TweenLite.to(this.$refs.info, duration, {
        x: '0%',
        ease: easing,
        clearProps: 'all',
        onComplete: () => {
          this.layout()
        }
      })
    },

    async transitionInfoInFull() {
      this.$store.commit('hideGalleryControl')

      const duration = 0.5
      const easing = Power1.easeInOut

      // Scroll up first
      TweenLite.to(window, duration, {
        scrollTo: 0
      })

      const flipping = new Flipping()
      flipping.read()

      this.$store.commit('showInfo')
      this.contentTargetSize = true
      await this.$nextTick()

      flipping.flip()
      this.contentTargetSize = false
      const scale = 1 / flipping.states.content.delta.width

      // Content
      TweenLite.set(this.$refs.content, {
        position: 'absolute',
        scale: 1,
        transformOrigin: 'top right'
      })

      TweenLite.to(this.$refs.content, duration, {
        scale: scale,
        ease: easing,
        onComplete: () => {
          TweenLite.set(this.$refs.content, {
            position: 'fixed'
          })
        }
      })

      // Header
      TweenLite.set(this.$refs.header, {
        left: flipping.states.header.bounds.left,
        height: flipping.states.header.bounds.height,
        x: flipping.states.header.delta.left,
        y: 0,
        position: 'fixed'
      })

      TweenLite.to(this.$refs.header, duration, {
        x: 0,
        ease: easing,
        clearProps: 'all'
      })

      // ToggleInfo
      TweenLite.set(this.$refs.toggleInfo, {
        x: flipping.states.header.delta.left
      })

      TweenLite.to(this.$refs.toggleInfo, duration, {
        x: 0,
        ease: easing,
        clearProps: 'all'
      })

      // Info
      TweenLite.set(this.$refs.info, {
        x: '-100%',
        width: flipping.states.info.bounds.width,
        height: flipping.states.info.bounds.height
      })

      TweenLite.to(this.$refs.info, duration, {
        x: '0%',
        ease: easing,
        clearProps: 'all',
        onComplete: () => {
          this.layout()
        }
      })
    },

    transitionInfoOut() {
      if (this.isMobile) this.transitionInfoOutMobile()
      else this.transitionInfoOutFull()
    },

    async transitionInfoOutMobile() {
      const duration = 0.5
      const easing = Power1.easeInOut

      // Scroll up first
      TweenLite.to(window, duration, {
        scrollTo: 0
      })

      TweenLite.set(this.$refs.content, {
        clearProps: 'all'
      })

      const flipping = new Flipping()
      flipping.read()

      this.$store.commit('hideInfo')
      await this.$nextTick()
      flipping.flip()

      // Content
      TweenLite.set(this.$refs.content, {
        position: 'absolute',
        left: flipping.states.content.bounds.left,
        x: flipping.states.content.delta.left
      })

      TweenLite.to(this.$refs.content, duration, {
        x: 0,
        ease: easing,
        clearProps: 'all'
      })

      // Header
      TweenLite.set(this.$refs.header, {
        position: 'fixed',
        left: flipping.states.header.bounds.left,
        height: flipping.states.header.bounds.height,
        x: flipping.states.header.delta.left,
        y: 0
      })

      TweenLite.to(this.$refs.header, duration, {
        x: 0,
        ease: easing,
        clearProps: 'all'
      })

      // ToggleInfo
      TweenLite.set(this.$refs.toggleInfo, {
        x: flipping.states.header.delta.left
      })

      TweenLite.to(this.$refs.toggleInfo, duration, {
        x: 0,
        ease: easing,
        clearProps: 'all'
      })

      // Info
      TweenLite.set(this.$refs.info, {
        position: 'absolute',
        left: flipping.states.info.previous.bounds.left,
        width: flipping.states.info.previous.bounds.width,
        height: flipping.states.info.previous.bounds.height,
        x: '0%',
        display: 'block'
      })

      TweenLite.to(this.$refs.info, duration, {
        x: '-100%',
        ease: easing,
        clearProps: 'all',
        onComplete: () => {
          this.$refs.info.style.display = 'none'
          this.$store.commit('showGalleryControl')
          this.layout()
        }
      })
    },

    async transitionInfoOutFull() {
      const duration = 0.5
      const easing = Power1.easeInOut

      // Scroll up first
      TweenLite.to(window, duration, {
        scrollTo: 0
      })

      const flipping = new Flipping()
      flipping.read()

      this.$store.commit('hideInfo')
      await this.$nextTick()
      // this line prevents to viewport to jump up suddenly:
      this.$refs.info.style.display = 'block'

      flipping.flip()

      // Content
      TweenLite.set(this.$refs.content, {
        // position: 'absolute'
      })

      TweenLite.to(this.$refs.content, duration, {
        scale: 1,
        transformOrigin: 'top right',
        ease: easing,
        clearProps: 'all'
      })

      // Header
      TweenLite.set(this.$refs.header, {
        position: 'fixed',
        left: flipping.states.header.bounds.left,
        height: flipping.states.header.bounds.height,
        x: flipping.states.header.delta.left,
        y: 0
      })

      TweenLite.to(this.$refs.header, duration, {
        x: 0,
        ease: easing,
        clearProps: 'all'
      })

      // ToggleInfo
      TweenLite.set(this.$refs.toggleInfo, {
        x: flipping.states.header.delta.left
      })

      TweenLite.to(this.$refs.toggleInfo, duration, {
        x: 0,
        ease: easing,
        clearProps: 'all'
      })

      // Info
      TweenLite.set(this.$refs.info, {
        x: '0%',
        left: flipping.states.info.previous.bounds.left,
        top: flipping.states.info.previous.bounds.top,
        width: flipping.states.info.previous.bounds.width,
        height: flipping.states.info.previous.bounds.height,
        display: 'block'
      })

      TweenLite.to(this.$refs.info, duration, {
        x: '-100%',
        ease: easing,
        clearProps: 'all',
        onComplete: () => {
          this.$refs.info.style.display = 'none'
          this.$store.commit('showGalleryControl')
          this.$eventHub.$emit('Cursor.default')
          this.layout()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.DefaultLayout {
  --headerWidth: 28px;
  --infoWidth: 0%;
  --logoPaddingBottom: 0;

  &.is-infoVisible {
    --infoWidth: calc(50% - (var(--headerWidth) / 2));

    @include media('<=700px') {
      --infoWidth: calc(100% - var(--headerWidth));
    }
  }

  padding-top: var(--padding);
  padding-bottom: var(--padding);
  display: flex;
  position: relative;
}

.DefaultLayout-info {
  width: var(--infoWidth);
  flex: none;
}

.DefaultLayout-header {
  flex: none;
  display: flex;
  flex-direction: row;
  width: var(--headerWidth);
  // position: sticky;
  position: fixed;
  top: 0;
  left: var(--infoWidth);
  height: 100%;
  padding-top: var(--padding);
  padding-bottom: var(--padding);
  line-height: 1.4;
}

.DefaultLayout-header,
.DefaultLayout-logo,
.DefaultLayout-toggleInfoText {
  writing-mode: vertical-lr;
  text-orientation: upright;
  // Strangely not supportet by autoprefixer:
  -webkit-text-orientation: upright;
}

.DefaultLayout-logo {
  overflow: hidden;
  margin-bottom: calc(var(--logoPaddingBottom) + 20px);
}

.DefaultLayout-logoFull {
  display: block;

  @include media('height<=450px') {
    display: none;
  }
}

.DefaultLayout-logoShort {
  display: none;

  @include media('height<=450px') {
    display: block;
  }
}

.DefaultLayout-toggleInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  left: var(--infoWidth);
  bottom: var(--padding);
  width: var(--headerWidth);

  .DefaultLayout.is-infoVisible & {
    // left: calc(var(--infoWidth) + var(--padding));
  }
}

.DefaultLayout-toggleInfoText {
  display: block;

  &.is-hidden {
    opacity: 0;
  }
}

.DefaultLayout-toggleInfoIcon {
  position: absolute;

  & svg {
    width: 80% !important;
  }
}

.DefaultLayout-hideInfo {
  width: calc(100% - var(--infoWidth));
  height: var(--AppHeight);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
}

.DefaultLayout-content {
  flex: none;
  // position: sticky; // causes flickering in firefox in combination with GalleryControl
  // height: 100%;
  min-height: calc(var(--AppHeight) - var(--padding) - var(--padding));
  width: calc(100% - var(--headerWidth) - var(--padding));
  margin-left: var(--headerWidth);

  @include media('<=mobile') {
    margin-left: 0;
    padding-left: var(--headerWidth);
    width: calc(100% - var(--padding));
  }

  &.is-targetSize {
    width: calc(100% - var(--infoWidth) - var(--headerWidth) - var(--padding));
  }
}

.DefaultLayout-contentSlot {
  .DefaultLayout.is-infoVisible & {
    pointer-events: none;
  }
}
</style>
