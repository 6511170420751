import forEach from 'lodash/forEach'

export const state = () => ({
  initialized: false,
  loadingItems: 0,
  infoVisible: false,
  galleryControlVisible: true,
  touchEnabled: false,
  scrollPositionForHome: undefined
})

export const mutations = {
  set(state, data) {
    forEach(data, (value, key) => {
      state[key] = value
    })
  },

  loadingStart(state) {
    state.loadingItems++
  },

  loadingEnd(state) {
    state.loadingItems--
  },

  toggleInfo(state) {
    state.infoVisible = !state.infoVisible
  },

  hideInfo(state) {
    state.infoVisible = false
  },

  showInfo(state) {
    state.infoVisible = true
  },

  hideGalleryControl(state) {
    state.galleryControlVisible = false
  },

  showGalleryControl(state) {
    state.galleryControlVisible = true
  },

  saveSrollPositionForHome(state) {
    if (!process.client) return

    state.scrollPositionForHome = document.scrollingElement.scrollTop
  }
}

export const getters = {}
export const actions = {}
