<template>
  <div class="ProjectOverviewItem" :class="classes.root">
    <nuxt-link :to="url" class="ProjectOverviewItem-content">
      <DefaultImage :noBackground="true">
        <img
          v-if="image"
          class="ProjectOverviewItem-image lazyload"
          :src="
            createSvgPlaceholder(
              image.width,
              image.height,
              $scssConstants['color-grey-200']
            )
          "
          data-sizes="auto"
          :data-srcset="getSrcset(image.urls)"
          :alt="image.description"
        />
      </DefaultImage>

      <div class="ProjectOverviewItem-title" v-html="title" v-if="title"></div>
    </nuxt-link>
  </div>
</template>

<script>
import lazysizes from '@/mixins/lazysizes'
import DefaultImage from '@/components/DefaultImage.vue'

export default {
  name: 'ProjectOverviewItem',
  components: { DefaultImage },
  mixins: [lazysizes],

  props: {
    image: { type: [Object, Boolean], default: false },
    title: { type: String, default: undefined },
    url: { type: String, required: true }
  },

  computed: {
    classes() {
      return {
        root: {
          'is-portrait': this.isPortrait
        }
      }
    },

    isPortrait() {
      if (!this.image) return
      return this.image.width < this.image.height
    }
  }
}
</script>

<style lang="scss">
.ProjectOverviewItem {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (1 / 1) * 100%;

    @include media('<=550px') {
      display: none;
    }
  }
}

.ProjectOverviewItem-content {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;

  @include media('<=550px') {
    position: relative;

    .ProjectOverviewItem.is-portrait & {
      width: 75%;
    }
  }
}

.ProjectOverviewItem-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left top;
}

.ProjectOverviewItem-title {
  font-size: var(--fontSize-large);
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  padding: 6px 8px;
  opacity: 0;

  @include transition-hover;

  @media (hover: hover) and (pointer: fine) {
    .ProjectOverviewItem:hover & {
      opacity: 1;
    }
  }
}
</style>
