exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ContentText{margin-top:1em;margin-bottom:1em}.ContentText:first-child{margin-top:0}.ContentText:last-child{margin-bottom:0}.ContentText p{margin-bottom:1em}", ""]);

// Exports
exports.locals = {
	"color-grey-100": "#fafafa",
	"color-grey-200": "#e6e6e6",
	"color-grey-300": "#dcdcdc",
	"color-grey-400": "#d2d2d2",
	"color-grey-500": "#c8c8c8",
	"color-grey-900": "#282828"
};