<template>
  <div class="ContentText defaultText" v-html="text"></div>
</template>

<script>
export default {
  name: 'ContentText',

  props: {
    text: { type: String, required: true }
  }
}
</script>

<style lang="scss">
.ContentText {
  margin-top: 1em;
  margin-bottom: 1em;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  & p {
    margin-bottom: 1em;
  }
}
</style>
