<template>
  <div class="DefaultImage" :class="classes" @click="$emit('click')">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DefaultImage',

  props: {
    aspect: { type: String, default: undefined },
    noBackground: { type: Boolean, default: false }
  },

  computed: {
    classes() {
      return {
        'is-withAspectRatio': this.aspect !== undefined,
        'is-4to3': this.aspect === '4:3',
        'is-1to1': this.aspect === '1:1',
        [`${this.$options.name}--noBackground`]: this.noBackground
      }
    }
  }
}
</script>

<style lang="scss">
.DefaultImage {
  background-color: $color-grey-200;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 1;

  & .lazyloading,
  & .lazyload {
    opacity: 0;
  }

  & .lazyloaded {
    opacity: 1;
    animation-duration: 0.25s;
    animation-name: fadeIn;
    animation-fill-mode: both;
  }

  &--noBackground {
    background-color: transparent;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.DefaultImage.is-withAspectRatio {
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (1 / 1) * 100%;
  }

  &.is-1to1::before {
    padding-top: (1 / 1) * 100%;
  }

  &.is-4to3::before {
    padding-top: (3 / 4) * 100%;
  }

  /* stylelint-disable */
  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  /* stylelint-enable */
}
</style>
