export default ({ app }) => {
  // get defaults on client again, if there's change since last prerendering:
  if (process.client) {
    app.store.dispatch('data/getDefaults')
  }

  // Every time the route changes (fired on initialization too)
  app.router.beforeEach(async (to, from, next) => {
    if (from.name === 'home') {
      app.store.commit('saveSrollPositionForHome')
    }

    // show info on /info for SEO
    if (to.path === '/info') {
      app.store.commit('showInfo')
    }

    if (app.store.state.initialized) {
      return next()
    }

    await app.store.dispatch('data/getDefaults')

    next({
      path: to.fullPath,
      replace: true
    })
  })
}
