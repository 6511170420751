exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".GalleryControlItem{-ms-flex-negative:0;flex-shrink:0;text-align:center;-webkit-font-feature-settings:\"ss04\";font-feature-settings:\"ss04\"}.GalleryControlItem.is-active{-webkit-font-feature-settings:\"ss02\";font-feature-settings:\"ss02\"}.GalleryControlItem-button{padding:0 15px}", ""]);

// Exports
exports.locals = {
	"color-grey-100": "#fafafa",
	"color-grey-200": "#e6e6e6",
	"color-grey-300": "#dcdcdc",
	"color-grey-400": "#d2d2d2",
	"color-grey-500": "#c8c8c8",
	"color-grey-900": "#282828"
};