exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ProjectOverviewItem{position:relative}.ProjectOverviewItem::before{content:\"\";display:block;width:100%;padding-top:100%}@media(max-width: 550px){.ProjectOverviewItem::before{display:none}}.ProjectOverviewItem-content{display:block;position:absolute;top:0;left:0;bottom:0;width:100%}@media(max-width: 550px){.ProjectOverviewItem-content{position:relative}.ProjectOverviewItem.is-portrait .ProjectOverviewItem-content{width:75%}}.ProjectOverviewItem-image{width:100%;height:100%;-o-object-fit:contain;object-fit:contain;-o-object-position:left top;object-position:left top}.ProjectOverviewItem-title{font-size:var(--fontSize-large);position:absolute;width:100%;bottom:0;background-color:#fff;padding:6px 8px;opacity:0;-webkit-transition:all .2s;transition:all .2s;-webkit-transition-property:opacity color;transition-property:opacity color}.ProjectOverviewItem-title:hover{-webkit-transition:all .1s;transition:all .1s;-webkit-transition-property:opacity color;transition-property:opacity color}@media(hover: hover)and (pointer: fine){.ProjectOverviewItem:hover .ProjectOverviewItem-title{opacity:1}}", ""]);

// Exports
exports.locals = {
	"color-grey-100": "#fafafa",
	"color-grey-200": "#e6e6e6",
	"color-grey-300": "#dcdcdc",
	"color-grey-400": "#d2d2d2",
	"color-grey-500": "#c8c8c8",
	"color-grey-900": "#282828"
};