exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "*{cursor:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=),auto !important}.CustomCursor{width:30vw;height:30vw;max-width:250px;max-height:250px;position:fixed;top:50px;left:200px;border-radius:1000px;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);z-index:1000;pointer-events:none}.CustomCursor.v-enter-active,.CustomCursor.v-leave-active{-webkit-transition:opacity .1s linear;transition:opacity .1s linear}.CustomCursor.v-enter,.CustomCursor.v-leave-to{opacity:0}.CustomCursor.v-leave,.CustomCursor.v-enter-to{opacity:1}", ""]);

// Exports
exports.locals = {
	"color-grey-100": "#fafafa",
	"color-grey-200": "#e6e6e6",
	"color-grey-300": "#dcdcdc",
	"color-grey-400": "#d2d2d2",
	"color-grey-500": "#c8c8c8",
	"color-grey-900": "#282828"
};