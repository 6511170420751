<template>
  <transition>
    <ul class="GalleryControl">
      <GalleryControlItem
        v-for="index in count"
        :key="index"
        :active="index === currentIndex"
        :title="index"
        @click="$emit('goToIndex', index - 1)"
      />

      <GalleryControlItem
        :active="descriptionVisible"
        v-if="hasDescription"
        title="T"
        @click="$emit('toggleDescription')"
      />
    </ul>
  </transition>
</template>

<script>
import GalleryControlItem from '@/components/GalleryControlItem.vue'

export default {
  name: 'GalleryControl',
  components: { GalleryControlItem },

  props: {
    count: { type: Number, default: 0 },
    hasDescription: { type: Boolean, default: false },
    currentIndex: { type: Number, default: undefined },
    descriptionVisible: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss">
.GalleryControl {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 50px;
  line-height: 1.2;

  &.v-enter-active,
  &.v-leave-active {
    transition: opacity 0.2s linear;
  }

  &.v-enter,
  &.v-leave-to {
    opacity: 0;
  }

  &.v-leave,
  &.v-enter-to {
    opacity: 1;
  }
}
</style>
