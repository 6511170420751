<template>
  <div class="Home">
    <ProjectOverview />
  </div>
</template>

<script>
import ProjectOverview from '@/components/ProjectOverview.vue'

export default {
  name: 'Home',
  layout: process.env.VUE_APP_DEFAULT_LAYOUT,
  components: { ProjectOverview }
}
</script>
