import axios from 'axios'
import forEach from 'lodash/forEach'

export const state = () => ({})

export const mutations = {
  set(state, data) {
    forEach(data, (value, key) => {
      state[key] = value
    })
  }
}

export const getters = {}

export const actions = {
  async getDefaults({ state, commit }) {
    const { data } = await axios.get('defaults')

    // only overwrite if something changed since last prerendering:
    if (JSON.stringify(state) !== JSON.stringify(data)) {
      commit('set', data)
    }

    commit('set', { initialized: true }, { root: true })
  }
}
