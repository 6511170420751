<template>
  <transition>
    <div class="CustomCursor" :style="style" v-show="visible">
      <!-- <CursorDefault v-if="cursor === 'default'" />
      <CursorPrevious v-if="cursor === 'previous'" />
      <CursorNext v-if="cursor === 'next'" /> -->

      <svg
        v-if="cursor === 'default'"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="12" fill-rule="evenodd"></circle>
      </svg>

      <svg
        v-if="cursor === 'previous'"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24 4v16L0 12z" fill-rule="evenodd"></path>
      </svg>

      <svg
        v-if="cursor === 'next'"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 4l24 8-24 8z" fill-rule="evenodd"></path>
      </svg>
    </div>
  </transition>
</template>

<script>
// import CursorDefault from '@/assets/svg/CursorDefault.svg'
// import CursorPrevious from '@/assets/svg/CursorPrevious.svg'
// import CursorNext from '@/assets/svg/CursorNext.svg'

const map = function(value, low1, high1, low2, high2) {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1)
}

export default {
  name: 'CustomCursor',
  // components: { CursorDefault, CursorPrevious, CursorNext },

  data() {
    return {
      cursor: 'default',
      visible: false
    }
  },

  computed: {
    style() {
      if (!process.client) return

      const scale = map(this.distance, 0, 1, 0.15, 1)

      return {
        left: `${this.$window.mouseX}px`,
        top: `${this.$window.mouseY}px`,
        transform: `translate(-50%, -50%) scale(${scale})`
      }
    },

    distance() {
      if (!process.client) return

      let distanceFactor = this.$store.getters[
        'distance/getDistanceToClosestPointFor'
      ]({
        x: this.$window.mouseX,
        y: this.$window.mouseY
      })

      return distanceFactor
    }
  },

  watch: {
    $route(newValue, oldValue) {
      if (newValue.name === oldValue.name) return
      if (newValue.path !== '/') return
      setTimeout(this.changeCursorToDefault, 500)
    }
  },

  created() {
    if (!process.client) return

    this.$eventHub.$on('Cursor.previous', this.changeCursorToPrevious)
    this.$eventHub.$on('Cursor.next', this.changeCursorToNext)
    this.$eventHub.$on('Cursor.default', this.changeCursorToDefault)

    window.addEventListener('mousemove', this.showForTheFirstTime)
    document.addEventListener('mouseleave', this.hide)
    document.addEventListener('mouseenter', this.show)
  },

  beforeDestroy() {
    if (!process.client) return

    this.$eventHub.$off('Cursor.previous', this.changeCursorToPrevious)
    this.$eventHub.$off('Cursor.next', this.changeCursorToNext)
    this.$eventHub.$off('Cursor.default', this.changeCursorToDefault)

    window.removeEventListener('mousemove', this.showForTheFirstTime)
    document.removeEventListener('mouseleave', this.hide)
    document.removeEventListener('mouseenter', this.show)
  },

  methods: {
    changeCursorTo(type) {
      // console.log(`change cursor to: ${type}`)
      this.cursor = type
    },

    changeCursorToPrevious() {
      this.changeCursorTo('previous')
    },

    changeCursorToNext() {
      this.changeCursorTo('next')
    },

    changeCursorToDefault() {
      this.changeCursorTo('default')
    },

    showForTheFirstTime() {
      this.visible = true
      window.removeEventListener('mousemove', this.show)
    },

    show() {
      this.visible = true
    },

    hide() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss">
/* stylelint-disable */
* {
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=),
    auto !important;
}
/* stylelint-enable*/

.CustomCursor {
  width: 30vw;
  height: 30vw;
  max-width: 250px;
  max-height: 250px;
  // background-color: rgba(#000, 0.1);
  position: fixed;
  top: 50px;
  left: 200px;
  border-radius: 1000px;
  transform: translate(-50%, -50%);
  z-index: 1000;
  pointer-events: none;

  & svg {
    // fill: rgba(red, 0.5);
  }

  &.v-enter-active,
  &.v-leave-active {
    transition: opacity 0.1s linear;
  }

  &.v-enter,
  &.v-leave-to {
    opacity: 0;
  }

  &.v-leave,
  &.v-enter-to {
    opacity: 1;
  }
}
</style>
