<template>
  <div class="App">
    <template v-if="debugPoints">
      <div
        class="App-debugPoint"
        v-for="(point, key) in points"
        :key="key"
        :style="{
          transform: `translate(${point.x}px, ${point.y}px`
        }"
      >
        x
      </div>
    </template>

    <CustomCursor v-if="!touchEnabled" />
    <component :is="layoutComponent">
      <nuxt :nuxtChildKey="routerKey" />
    </component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DefaultLayout from '@/layouts/DefaultLayout'
import NakedLayout from '@/layouts/NakedLayout'
import CustomCursor from '@/components/CustomCursor'
import layout from '@/mixins/layout'

export default {
  name: 'App',
  components: { DefaultLayout, NakedLayout, CustomCursor }, // eslint-disable-line
  mixins: [layout],

  data() {
    return {
      debugPoints: false
    }
  },

  head() {
    return {
      title: this.$route.meta.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.metaDescription
        }
      ]
    }
  },

  computed: {
    ...mapState(['touchEnabled']),
    ...mapState('distance', ['points']),
    ...mapState('data', ['metaDescription']),

    layoutComponent() {
      if (this.$route.meta.layout) return `${this.$route.meta.layout}Layout`
      return 'DefaultLayout'
    },

    routerKey() {
      return String(this.$route.meta.id)
    }
  },

  created() {
    if (!process.client) return

    window.addEventListener('touchstart', this.activateTouchInterface)
  },

  beforeDestroy() {
    window.removeEventListener('touchstart', this.activateTouchInterface)
  },

  methods: {
    layout() {
      this.$el.style.setProperty('--AppHeight', `${window.innerHeight}px`)
    },

    activateTouchInterface() {
      this.$store.commit('set', { touchEnabled: true })
    }
  }
}
</script>

<style lang="scss">
/* stylelint-disable*/
@import '@/../node_modules/reset-css/sass/_reset.scss';
@import '@/assets/scss/default.scss';
@import '@/assets/scss/defaultText.scss';

@include font-face('MSPERA', '~assets/fonts/msperav24-regular-webfont');

.App {
  --AppHeight: 100%;

  position: relative;
}

.App-debugPoint {
  position: fixed;
  z-index: 999;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-left: -5px;
  margin-top: -5px;
  background: red;
}

.page-enter-active,
.page-leave-active {
  transition: opacity 0.25s;
}

.page-enter,
.page-leave-active {
  opacity: 0;
}
</style>
