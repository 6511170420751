<template>
  <div class="Info">
    <template v-for="item in info.content">
      <ContentList
        v-if="item.type === 'table'"
        :key="item.id"
        :list="item.table"
        :title="item.title"
        :address="item.id === 1050"
      />

      <ContentText
        v-if="item.type === 'body'"
        :key="item.id"
        :text="item.body"
        :title="item.title"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ContentList from '@/components/ContentList.vue'
import ContentText from '@/components/ContentText.vue'

export default {
  name: 'Info',
  components: { ContentList, ContentText },

  computed: {
    ...mapState('data', ['info'])
  }
}
</script>

<style lang="scss">
.Info {
  padding-left: var(--padding);
  padding-right: var(--padding);
}
</style>
