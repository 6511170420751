import Vue from 'vue'
import axios from 'axios'
import scssConstants from '@/assets/scss/constants.scss'
import Icon from '@/components/Icon.vue'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['X-API-Key'] = process.env.VUE_APP_API_KEY
Vue.config.productionTip = false
Vue.prototype.$eventHub = new Vue()
Vue.prototype.$scssConstants = scssConstants
Vue.component('Icon', Icon)
