<template>
  <div class="GalleryItem">
    <DefaultImage :noBackground="true">
      <img
        v-if="image"
        class="GalleryItem-image lazyload"
        :src="
          createSvgPlaceholder(
            image.width,
            image.height,
            $scssConstants['color-grey-200']
          )
        "
        data-sizes="auto"
        :data-srcset="getSrcset(image.urls)"
        :alt="image.description"
        :style="style.image"
        data-flip-key="image"
        ref="image"
      />
    </DefaultImage>
  </div>
</template>

<script>
import DefaultImage from '@/components/DefaultImage.vue'
import lazysizes from '@/mixins/lazysizes'
import layout from '@/mixins/layout'

export default {
  name: 'GalleryItem',
  components: { DefaultImage },
  mixins: [lazysizes, layout],

  data() {
    return {
      imageWidth: undefined,
      imageHeight: undefined,
      isMobile: undefined
    }
  },

  props: {
    image: { type: [Object, Boolean], default: false },
    visible: {},
    descriptionVisible: { type: Boolean, required: true }
  },

  computed: {
    style() {
      return {
        image: {
          width: this.imageWidth ? `${this.imageWidth}px` : undefined,
          height: this.imageHeight ? `${this.imageHeight}px` : undefined
        }
      }
    }
  },

  watch: {
    descriptionVisible: 'layout'
  },

  async mounted() {
    await this.$nextTick()

    this.layout()
  },

  methods: {
    async layout() {
      this.isMobile = window.matchMedia('(max-width: 700px)').matches

      if (this.isMobile) {
        this.imageWidth = undefined
        this.imageHeight = undefined
        return
      }

      const rect = this.$el.getBoundingClientRect()

      this.imageWidth = rect.width
      this.imageHeight = rect.width * this.image.ratio

      if (this.imageHeight > rect.height) {
        this.imageHeight = rect.height
        this.imageWidth = rect.height / this.image.ratio
      }
    }
  }
}
</script>

<style lang="scss">
.GalleryItem {
  height: 100%;

  &.v-enter-active,
  &.v-leave-active {
    transition: opacity 0.25s linear;
  }

  &.v-enter,
  &.v-leave-to {
    opacity: 0;
  }

  &.v-leave,
  &.v-enter-to {
    opacity: 1;
  }
}

.GalleryItem-image {
  display: block;
  position: absolute;

  @include media('<=mobile') {
    width: 100%;
    max-height: calc(var(--AppHeight) - var(--padding) - var(--padding));
    object-fit: contain;
    object-position: top left;
  }
}
</style>
