<template>
  <li class="GalleryControlItem" :class="classes">
    <button class="GalleryControlItem-button" @click="$emit('click')">
      {{ title }}
    </button>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import layout from '@/mixins/layout'

export default {
  name: 'GalleryControlItem',
  mixins: [layout],

  props: {
    title: { type: [String, Number], required: true },
    active: { type: Boolean, default: false }
  },

  computed: {
    ...mapState(['galleryControlVisible']),

    classes() {
      return {
        'is-active': this.active
      }
    },

    id() {
      return `GalleryControlItem.${this.title}`
    }
  },

  watch: {
    galleryControlVisible: 'layout'
  },

  beforeDestroy() {
    this.$store.commit('distance/removePoint', this.id)
  },

  methods: {
    layout() {
      if (!this.galleryControlVisible) {
        this.$store.commit('distance/removePoint', this.id)
        return
      }

      const rect = this.$el.getBoundingClientRect()
      this.$store.commit('distance/setPoint', {
        id: this.id,
        point: {
          x: rect.x + rect.width / 2,
          y: rect.y + rect.height / 2
        }
      })
    }
  }
}
</script>

<style lang="scss">
.GalleryControlItem {
  flex-shrink: 0;
  // min-width: 80px;
  text-align: center;
  font-feature-settings: 'ss04';

  &.is-active {
    font-feature-settings: 'ss02';
  }
}

.GalleryControlItem-button {
  padding: 0 15px;
}
</style>
