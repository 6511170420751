exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".GalleryControl{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-ms-flex-wrap:wrap;flex-wrap:wrap;font-size:50px;line-height:1.2}.GalleryControl.v-enter-active,.GalleryControl.v-leave-active{-webkit-transition:opacity .2s linear;transition:opacity .2s linear}.GalleryControl.v-enter,.GalleryControl.v-leave-to{opacity:0}.GalleryControl.v-leave,.GalleryControl.v-enter-to{opacity:1}", ""]);

// Exports
exports.locals = {
	"color-grey-100": "#fafafa",
	"color-grey-200": "#e6e6e6",
	"color-grey-300": "#dcdcdc",
	"color-grey-400": "#d2d2d2",
	"color-grey-500": "#c8c8c8",
	"color-grey-900": "#282828"
};