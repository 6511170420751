import Vue from 'vue'

export default new Vue({
  data() {
    return {
      mouseX: 0,
      mouseY: 0
    }
  },

  created() {
    window.addEventListener('mousemove', this.setMouseCoordinates)
  },

  methods: {
    setMouseCoordinates(event) {
      this.mouseX = event.clientX
      this.mouseY = event.clientY
    }
  }
})
